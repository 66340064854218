import React, { useState, useEffect, useCallback, useRef } from "react";
import ReactGA from "react-ga4";
import { Route, Routes, Link, Navigate } from "react-router-dom";
import axios from "axios";
import { getToken } from './auth';
import './index.css';
import logo from './logo.png';
import noImageAvailable from './assets/no-image-available.png';
import AdminPage from './components/AdminPage';
import LoginPage from './components/LoginPage';
import './App.css';
import ProtectedRoute from './components/ProtectedRoute';

const SearchInput = React.memo(({ value, onChange, currentProducts }) => {
    const [placeholderIndex, setPlaceholderIndex] = useState(0);
    const [placeholders, setPlaceholders] = useState([
        "Search products by № or Title",
        "Try 'iPhone 12' or 'A1234'",
        "Search for 'Laptop' or 'B5678'",
        "Find 'Headphones' or 'C9101'"
    ]);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        ReactGA.initialize("G-V3WSR1FEXP"); // Updated Measurement ID
    }, []);

    useEffect(() => {
        if (currentProducts && currentProducts.length > 0) {
            const newPlaceholders = currentProducts.reduce((acc, product) => {
                if (product.title) acc.push(`Try '${product.title.slice(0, 20)}...'`);
                if (product.product_code) acc.push(`Search for '${product.product_code}'`);
                return acc;
            }, []);

            if (newPlaceholders.length > 0) {
                setPlaceholders(prevPlaceholders => [
                    ...prevPlaceholders.slice(0, 1),
                    ...newPlaceholders.slice(0, 3)
                ]);
            }
        }
    }, [currentProducts]);

    useEffect(() => {
        const interval = setInterval(() => {
            setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
        }, 4000); // Change placeholder every 4 seconds

        return () => clearInterval(interval);
    }, [placeholders]);

    return (
        <input
            type="text"
            placeholder={placeholders[placeholderIndex]}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            autoFocus={isFocused || value.length > 0}
        />
    );
});

function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

function App() {
    const [products, setProducts] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState("");
    const [search, setSearch] = useState("");
    const [offset, setOffset] = useState(0);
    const [searchOffset, setSearchOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [error, setError] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const debouncedSearchRef = useRef(null);
    const [searchHasMore, setSearchHasMore] = useState(true);
    const lastProductRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const api = axios.create({
        baseURL: (process.env.REACT_APP_API_URL || "https://www.dailysaleshunt.com") + "/api",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getToken()}`
        }
    });

    const loadProducts = useCallback(async (tag = "", limit = 30, offset = 0, forceLoad = false) => {
        if ((loading || !hasMore) && !forceLoad) {
            return;
        }
        setLoading(true);
        try {
            // Use the encoded tag in the URL
            let url = `/products?limit=${limit}&offset=${offset}`;
            if (tag) {
                url += `&tag=${tag}`;
            }

            const result = await api.get(url);

            // Check if the response is HTML instead of JSON
            if (typeof result.data === 'string' && result.data.startsWith('<!doctype html>')) {
                throw new Error("Received HTML instead of JSON. API endpoint might be incorrect.");
            }

            // Check if the response has the expected structure
            if (result.data && result.data.products && Array.isArray(result.data.products)) {
                const { products: newProducts, has_more } = result.data;

                setProducts(prevProducts => {
                    const updatedProducts = offset === 0 || forceLoad ? newProducts : [...prevProducts, ...newProducts];
                    return updatedProducts;
                });
                setHasMore(has_more);
                setOffset(offset + newProducts.length);
                setError("");
            } else {
                throw new Error("Invalid response structure");
            }
        } catch (error) {
            console.error("Failed to load products:", error);
            setError("Failed to load products. Please try again.");
            setProducts([]);  // Reset products to an empty array
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    }, [loading, hasMore, api]);

    const loadProductsBySearch = useCallback(async (query, limit = 20, offset = 0) => {
        if (loading) {
            return;
        }
        setLoading(true);
        try {
            const trimmedQuery = query.trim();
            const result = await api.get(`/products/search?q=${encodeURIComponent(trimmedQuery)}&limit=${limit}&offset=${offset}`);

            const { products: newProducts, has_more, total_count } = result.data;

            setSearchResults(prevResults => {
                const updatedResults = offset === 0 ? newProducts : [...prevResults, ...newProducts];
                return updatedResults;
            });
            setSearchHasMore(has_more);
            setSearchOffset(prevOffset => prevOffset + newProducts.length);
            setError("");

        } catch (error) {
            console.error("Search failed:", error);
            setError("Search failed. Please try again.");
        } finally {
            setLoading(false);
        }
    }, [loading, api]);

    useEffect(() => {
        debouncedSearchRef.current = debounce((value) => {
            const trimmedValue = value.trim();
            if (trimmedValue === "") {
                setIsSearching(false);
                setSearchResults([]);
                if (selectedTag) {
                    loadProducts(selectedTag, 30, 0, true);
                } else {
                    loadProducts("", 30, 0, true);
                }
            } else {
                setIsSearching(true);
                loadProductsBySearch(trimmedValue);
            }
        }, 300);
    }, [selectedTag, loadProducts, loadProductsBySearch]);

    useEffect(() => {
        if (search === "") {
            setIsSearching(false);
            setSearchResults([]);
            if (selectedTag) {
                loadProducts(selectedTag, 30, 0, true);
            } else {
                loadProducts("", 30, 0, true);
            }
        } else {
            setIsSearching(true);
            loadProductsBySearch(search);
        }
    }, [search, selectedTag]);


    const handleSearch = useCallback((value) => {
        setSearch(value);
        setSearchOffset(0);
        setSearchHasMore(true);
        if (value.trim() !== "") {
            ReactGA.event({
                category: "User",
                action: "Performed Search",
                label: value
            });

            setIsSearching(true);
            loadProductsBySearch(value, 20, 0);
        } else {
            setSearchResults([]);
            setIsSearching(false);
            loadProducts("", 20, 0, true);
        }
    }, [loadProductsBySearch, loadProducts]);

    const handleTagClick = (tag) => {
        ReactGA.event({
            category: "User",
            action: "Clicked Tag",
            label: tag
        });

        // Encode the tag before setting it
        const encodedTag = encodeURIComponent(tag);
        setSelectedTag(encodedTag);
        setSearch("");
        setIsSearching(false);
        setSearchResults([]);
        setOffset(0);
        setSearchOffset(0);
        setHasMore(true);
        loadProducts(encodedTag, 30, 0, true);
    };

    const searchInputRef = useRef(null);

    const loadMoreProducts = useCallback((event) => {
        event.preventDefault();
        if (!loading && hasMore && !isLoadingMore) {
            setIsLoadingMore(true);
            ReactGA.event({
                category: "User",
                action: "Clicked Load More",
                label: selectedTag || "All Products"
            });

            const lastProduct = document.querySelector('#products .product-card:last-child');
            loadProducts(selectedTag, 30, offset).then(() => {
                setTimeout(() => {
                    if (lastProduct) {
                        lastProduct.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                    setIsLoadingMore(false);
                }, 100);
            });
        }
    }, [loading, hasMore, isLoadingMore, selectedTag, offset, loadProducts]);

    const loadMoreSearchResults = useCallback((event) => {
        event.preventDefault();
        if (!loading && searchHasMore && !isLoadingMore) {
            setIsLoadingMore(true);
            ReactGA.event({
                category: "User",
                action: "Clicked Load More Search Results",
                label: search
            });

            const lastProduct = document.querySelector('#products .product-card:last-child');
            loadProductsBySearch(search, 20, searchOffset).then(() => {
                setTimeout(() => {
                    if (lastProduct) {
                        lastProduct.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                    setIsLoadingMore(false);
                }, 100);
            });
        }
    }, [loading, searchHasMore, isLoadingMore, search, searchOffset, loadProductsBySearch]);

    const loadTags = useCallback(async () => {
        try {
            const result = await api.get('/tags');
            if (Array.isArray(result.data)) {
                setTags(result.data);
            } else {
                console.error("Unexpected tags data structure:", result.data);
                setTags([]);
            }
        } catch (error) {
            console.error("Failed to load tags:", error);
            setError("Failed to load tags. Please try again.");
            setTags([]);
        }
    }, [api]);

    const loadRandomProducts = useCallback(async () => {
        setLoading(true);
        try {
            const result = await api.get('/products/random');
            if (result.data && Array.isArray(result.data)) {
                setProducts(result.data);
                setError("");
            } else {
                throw new Error("Invalid response structure");
            }
        } catch (error) {
            console.error("Failed to load random products:", error);
            setError("Failed to load random products. Please try again.");
            setProducts([]);  // Reset products to an empty array
        } finally {
            setLoading(false);
        }
    }, [api]);

    useEffect(() => {
        if (window.location.pathname === "/") {
            loadRandomProducts();
            loadTags();
        } else {
            loadTags();
            loadRandomProducts();
        }
    }, []); // Empty dependency array to ensure this runs only once on mount

    useEffect(() => {
        // Add this effect to dynamically insert preload links
        const preloadLCPImage = () => {
            const productsToPreload = isSearching ? searchResults : products;
            const preloadCount = 3; // Preload the first 3 images

            productsToPreload.slice(0, preloadCount).forEach((product, index) => {
                if (product.media_url) {
                    const link = document.createElement('link');
                    link.rel = 'preload';
                    link.as = 'image';
                    link.href = `https://www.dailysaleshunt.com${product.media_url}`;
                    link.setAttribute('data-product-index', index.toString());
                    document.head.appendChild(link);
                }
            });
        };

        preloadLCPImage();

        // Cleanup function to remove preload links when component unmounts or dependencies change
        return () => {
            const preloadLinks = document.head.querySelectorAll('link[rel="preload"][as="image"]');
            preloadLinks.forEach(link => link.remove());
        };
    }, [products, searchResults, isSearching]);

    const handleLogin = (token, isAdmin) => {
        localStorage.setItem('token', token);
        localStorage.setItem('isAdmin', isAdmin);
        setIsLoggedIn(true);
        setIsAdmin(isAdmin === 'true' || isAdmin === true);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('isAdmin');
        setIsLoggedIn(false);
        setIsAdmin(false);
    };

    const ProductImage = ({ product, index }) => {
        return (
            <img
                src={product.media_url ? `https://www.dailysaleshunt.com${product.media_url}` : noImageAvailable}
                alt={product.title}
                loading={index < 30 ? "eager" : "lazy"} // Load first 3 images eagerly
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = noImageAvailable;
                }}
            />
        );
    };

    const ProductList = () => (
        <>
            <div className="search-container">
                <SearchInput 
                    value={search} 
                    onChange={handleSearch} 
                    currentProducts={isSearching ? searchResults : products}
                />
                <p className="search-hint">Search by title, keywords, or product code</p>
            </div>
            <div id="tags">
                {Array.isArray(tags) && tags.length > 0 ? (
                    tags.map((tag, index) => (
                        <button key={`${tag}-${index}`} onClick={() => handleTagClick(tag)}>
                            {tag}
                        </button>
                    ))
                ) : (
                    <p>No tags available</p>
                )}
            </div>
            <div id="products">
                {(isSearching ? searchResults : products).length > 0 ? (
                    (isSearching ? searchResults : products).map((product, index) => (
                        <div key={`${product.uid}-${index}`} className="product-card" ref={index === (isSearching ? searchResults : products).length - 1 ? lastProductRef : null}>
                            <a 
                                href={product.link} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                onClick={() => {
                                    ReactGA.event({
                                        category: "User",
                                        action: "Clicked Product",
                                        label: product.title,
                                        value: product.price
                                    });
                                }}
                            >
                                <div className="media-container">
                                    {product.media_url && product.media_url.toLowerCase().endsWith('.mp4') ? (
                                        <div className="video-wrapper">
                                            <video
                                                src={`https://www.dailysaleshunt.com${product.media_url}`}
                                                poster={product.thumbnail ? `https://www.dailysaleshunt.com/images/${product.thumbnail.replace(/\\/g, '/')}` : noImageAvailable}
                                                controls
                                                preload="metadata"
                                                loading={index === 0 ? "eager" : "lazy"}
                                            >
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    ) : (
                                        <ProductImage product={product} index={index} />
                                    )}
                                    <div className="product-code">{product.product_code}</div>
                                </div>
                                <h3>{product.title}</h3>
                                <p className="price">
                                    ${typeof product.price === 'number' ? product.price.toFixed(2) : 'N/A'}
                                </p>
                            </a>
                        </div>
                    ))
                ) : (
                    <p className="no-products">No products available</p>
                )}
            </div>
            {isSearching ? (
                searchHasMore && !loading && (
                    <div className="load-more-container">
                        <button onClick={loadMoreSearchResults} disabled={isLoadingMore}>
                            {isLoadingMore ? 'Loading...' : 'Load More Search Results'}
                        </button>
                    </div>
                )
            ) : (
                hasMore && !loading && (
                    <div className="load-more-container">
                        <button onClick={loadMoreProducts} disabled={isLoadingMore}>
                            {isLoadingMore ? 'Loading...' : 'Load More'}
                        </button>
                    </div>
                )
            )}
        </>
    );

    useEffect(() => {
        const token = localStorage.getItem('token');
        const storedIsAdmin = localStorage.getItem('isAdmin');
        if (token) {
            setIsLoggedIn(true);
            setIsAdmin(storedIsAdmin === 'true');
        }
        setIsLoading(false);
    }, []);

    const Footer = () => (
        <footer className="main-footer">
            <p className="copyright">© {new Date().getFullYear()} Special Offers. All rights reserved.</p>
            <p className="disclaimer">Prices may vary. Please click on the product link for the current, real-time price.</p>
        </footer>
    );

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container">
            <header className="main-header">
                <div className="logo-container">
                    <Link to="/">
                        <img src={logo} alt="Logo" className="logo" />
                    </Link>
                </div>
                <h1 className="site-title">
                    {"Special Offers".split('').map((char, index) => (
                        <span key={index}>{char === ' ' ? '\u00A0' : char}</span>
                    ))}
                </h1>
                <nav className="main-nav">
                    <ul>
                        {isAdmin && <li><Link to="/admin">Admin</Link></li>}
                        {isLoggedIn && (
                            <li><button className="logout-btn" onClick={handleLogout}>Logout</button></li>
                        )}
                    </ul>
                </nav>
            </header>

            <Routes>
                <Route path="/" element={<ProductList />} />
                <Route path="/login" element={
                    isLoggedIn ? <Navigate to="/" replace /> : <LoginPage onLogin={handleLogin} />
                } />
                <Route
                    path="/admin"
                    element={
                        <ProtectedRoute isAllowed={isAdmin} redirectPath="/">
                            <AdminPage />
                        </ProtectedRoute>
                    }
                />
            </Routes>

            <Footer />
        </div>
    );
}

export default App;