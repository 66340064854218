import React from 'react';

function ProgressBar({ progress }) {
    return (
        <div style={{ width: '100%', backgroundColor: '#e0e0e0', borderRadius: '5px', margin: '10px 0' }}>
            <div
                style={{
                    width: `${progress}%`,
                    backgroundColor: '#4CAF50',
                    height: '20px',
                    borderRadius: '5px',
                    textAlign: 'center',
                    lineHeight: '20px',
                    color: 'white'
                }}
            >
                {progress}%
            </div>
        </div>
    );
}

export default ProgressBar;