import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import { getToken } from '../auth';

function AdminPage() {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        const isAdmin = localStorage.getItem('isAdmin');
        if (!token || isAdmin !== 'true') {
            navigate('/login');
        }
    }, [navigate]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            alert('Please select a file');
            return;
        }
    
        setUploading(true);
        setProgress(0);
        const formData = new FormData();
        formData.append('file', file);
    
        try {
            const token = getToken();
            const response = await fetch('https://www.dailysaleshunt.com/api/products/upload', {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value);
                const lines = chunk.split('\n\n');
                for (const line of lines) {
                    if (line.startsWith('data: ')) {
                        const data = JSON.parse(line.slice(6));
                        const percentCompleted = Math.round((data.processed / data.total) * 100);
                        setProgress(percentCompleted);
                        if (data.completed) {
                            alert('File uploaded successfully.');
                            break;
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Upload failed:', error);
            alert('Upload failed');
        } finally {
            setUploading(false);
        }
    };

    return (
        <div>
            <h2>Admin Page</h2>
            <p>If you can see this, you have admin access.</p>
            <div>
                <input type="file" onChange={handleFileChange} disabled={uploading} />
                <button onClick={handleUpload} disabled={uploading}>
                    {uploading ? 'Uploading...' : 'Upload CSV'}
                </button>
            </div>
            {uploading && <ProgressBar progress={progress} />}
        </div>
    );
}

export default AdminPage;
